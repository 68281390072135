/**
* Generated automatically at built-time (2024-08-26T12:17:05.042Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "utekok-kollektioner-myoutdoorkitchen",templateKey: "sites/99-02d52083-6dd0-44fe-a8d2-d50b01d4056c"};