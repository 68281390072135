/**
* Generated automatically at built-time (2024-08-26T12:17:05.054Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "induplus",templateKey: "sites/99-7f2fe676-e17a-4731-bfa8-39261867d11d"};